<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        md="8"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>

            <b-row>
            <b-col md="4">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.code')"
                label-for="account-code"
              >
                <b-form-input
                  id="account-code"
                  size="sm"
                  v-model="form.accountNumber"
                  placeholder="2-1001"
                  :disabled="actions.isPreview"
                />
              </b-form-group>
            </b-col>


            <b-col md="8">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.name')"
                label-for="account-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                                    <!-- @input="changeName($event)" -->
                  <b-form-input
                    id="account-name"
                    size="sm"
                    v-model="form.accountName"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="!actions.isPreview ? $t('apps.masterDataCOA.placeholder.name') : null"
                    :disabled="actions.isPreview"
                  />
                  <small class="text-danger">{{ errors[0]}}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            

            <b-col md="12">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.name_df')"
                label-for="account-name-default"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="account-name-default"
                    size="sm"
                    v-model="form.accountNameDefault"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="!actions.isPreview ? $t('apps.masterDataCOA.placeholder.name_df') : null"
                    :disabled="actions.isPreview"
                  />
                  <small class="text-danger">{{ errors[0]}}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Column: Is Parent -->
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="form.isParent"
                  :options="options.isParent"
                  value-field="item"
                  text-field="name"
                  :disabled="actions.isPreview"
                />
                Is Parent
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-alert
                show
                dismissible
                variant="success"
                size="sm"
              >
                <div class="alert-body small">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50 mt-25"
                  />
                  {{ this.$t('apps.masterDataCOA.alert.isParent') }}
                </div>
              </b-alert>
            </b-col>

            <!-- Column: Description -->
            <b-col md="12">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.description')"
                label-for="account-description"
              >
                <b-form-textarea
                  id="account-description"
                  size="sm"
                  v-model="form.accountDescription"
                  :placeholder="!actions.isPreview ? $t('apps.masterDataCOA.placeholder.description') : null"
                  :disabled="actions.isPreview"
                />
              </b-form-group>
            </b-col>

            <!-- Column: Account Subtype -->
            <b-col md="8">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.subtype')"
                label-for="account-subtype"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <v-select
                    id="account-subtype"
                    v-model="form.accountSubtypeCode"
                    :options="LOV.accountSubtypes"
                    :reduce="field => field.code"
                    label="subtype_name"
                    @option:selected="handleSubtype"
                    :state="errors.length > 0 ? false:null"
                    :disabled="actions.isPreview"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0]}}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Column: Normal Balance -->
            <b-col md="4">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.normalBalance')"
              label-for="normal-balance"
              >
                <v-select
                  id="normal-balance"
                  v-model="form.normalBalance"
                  :options="options.normalBalance"
                  :reduce="field => field.value"
                  label="text"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="form.accountSubtypeCode">
              <b-form-group
                :label="$t('apps.masterDataCOA.singular.parentAccount')"
              >
                <v-select
                  id="account-detail"
                  v-model="form.accountParentId"
                  :options="LOV.accountParents"
                  :reduce="field => field.id"
                  label="account_name"
                    class="select-size-sm"
                  :disabled="actions.isPreview"
                >
                  <template #option="{ account_name, account_number }">
                    {{ `${account_number} - ${account_name}` }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            </b-row>

            <!-- <app-collapse type="margin">
              <app-collapse-item :title="$t('apps.masterDataCOA.singular.advanced')">
                <b-form-group
                  :label="$t('apps.masterDataCOA.singular.detail')"
                  label-for="account-detail"
                >
                  <v-select
                    id="account-detail"
                    v-model="details"
                    :options="LOV.details"
                    :reduce="field => field.value"
                    label="name"
                    :disabled="actions.isPreview"
                    class="select-size-sm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="details === 'child'"
                  :label="$t('apps.masterDataCOA.singular.from')"
                >
                  <v-select
                    id="account-detail"
                    v-model="form.accountParentId"
                    :options="LOV.accountParents"
                    :reduce="field => field.id"
                    label="account_name"
                    :disabled="actions.isPreview"
                  >
                    <template #option="{ account_name, account_number }">
                      {{ `${account_name} - ${account_number}` }}
                    </template>
                  </v-select>
                </b-form-group>

                <b-alert
                  variant="info"
                  show
                  fade
                  dismissible
                >
                  <h4 class="alert-heading">
                    <feather-icon icon="InfoIcon" />
                    Info
                  </h4>
                  <div class="alert-body">
                    <ul class="pl-2 mb-0">
                      <li>
                        <span> <span class="font-weight-bolder font-italic">{{ this.$t('apps.masterDataCOA.alert.standalone') }}</span> {{ this.$t('apps.masterDataCOA.alert.standaloneText') }}</span>
                      </li>
                      <li>
                        <span> <span class="font-weight-bolder font-italic">{{ this.$t('apps.masterDataCOA.alert.child') }}</span> {{ this.$t('apps.masterDataCOA.alert.childText') }}</span>
                      </li>
                    </ul>
                  </div>
                </b-alert>


              </app-collapse-item>
            </app-collapse> -->

            <b-table
              v-if="accountChildren.length > 0 && actions.isPreview"
              id="refListTable"
              ref="refListTable"
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="accountChildren"
              responsive
              primary-key="id"
              show-empty
              caption-top
              empty-text="No matching records found"
              class="mt-3 position-relative"
            >

              <template #table-caption>
                <h6 class="font-weight-bolder">Child Account List</h6>
              </template>

              <!-- Column: code -->
              <template #cell(code)="data">
                <b-link
                  v-if="data.item.is_account_parent"
                  :to="{ name: 'apps-master-data-coa-detail', params: { id: data.item.id }}"
                  class="d-block, text-nowrap, font-weight-bolder"
                  :style="setPadding(data.item)"
                >
                  {{ data.item.account_number }} - {{ data.item.account_name }}
                </b-link>

                <b-link
                  v-else
                  :to="{ name: 'apps-master-data-coa-detail', params: { id: data.item.id }}"
                  class="d-block, text-nowrap"
                  :style="setPadding(data.item)"
                >
                  {{ data.item.account_number }} - {{ data.item.account_name }}
                </b-link>
              </template>

            </b-table>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-coa-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="$can('delete', 'MdCoa') && actions.isPreview && form.account_subtype_code !== 'PARENT'"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-if="$can('update', 'MdCoa') && actions.isPreview"
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BAlert,
  BTable,
  BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// // import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import useFormResource from '@/comp-functions/useFormResource'
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BFormTextarea,
    BButton,
    BAlert,
    // BFormText,
    // BFormDatalist,
    BTable,
    BLink,
    vSelect,
    // AppCollapse,
    // AppCollapseItem,
    ValidationProvider,
    ValidationObserver
  },
  watch: {
    '$route.params.id' (to) {
      this.getDataPreview(to)
    }
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/accounts', localeContextPath: 'apps.masterDataCOA.singular.coa', redirectPathName: 'apps-master-data-coa' })

    const { $t } = useLocalization()

    // helper fro checking if the accounts is parent or not
    const details = ref('none')
    const isParent = ref(false)

    const accountChildren = ref([])

    // Table Handlers
    const tableColumns = [{ key: 'code', label: 'Account Name'}]

    const setPadding = (row) => {
      return `padding-left: ${(row.account_level - 1) * 30}px;`
    }

    // LOV (select option)
    const LOV = ref({
      accountTypes: [],
      accountSubtypes: [],
      accountChilds: [],
      accountParents: [],
      details: []
    })

    const form = ref({
      isAccountParent: false,
      accountName: '',
      accountNameDefault: '',
      accountNumber: '',
      accountSubtypeCode: '',
      accountParentId: '',
      accountDescription: '',
      isParent: false
    })


    // will get data to show the preview
    const getDataPreview = async (id = paramsId) => {
      const data = await get({ url: `master/accounts/${id}` })

      form.value.accountSubtypeCode = data.account.accountSubtypes.code
      form.value.accountNumber = data.account.account_number
      form.value.accountName = data.account.account_name
      form.value.accountNameDefault = data.account.account_name_default
      form.value.normalBalance = data.account.normal_balance
      form.value.accountDescription = data.account.account_description
      form.value.accountParentId = data.account.account_parent_id
      details.value = data.account.account_parent_id ? 'child' : 'none'
      isParent.value = data.account.is_account_parent
      accountChildren.value = []
      if (data.accountChild) {
        const firstChildLevel = Number(data.accountChild[0].account_level)
        const levelAdjustment = (firstChildLevel - 1)
        data.accountChild.map(field => {
          accountChildren.value.push({
            id: field.id,
            account_number: field.account_number,
            account_name: field.account_name,
            account_level: Number(field.account_level) - levelAdjustment,
            is_account_parent: field.is_account_parent
          })
        })
      }
    }

    // Get account types
    const getTypes = async () => {
      LOV.value.accountTypes = await get({ url: 'data/account-type'})
    }

    // Get account subtypes
    const getSubtypes = async () => {
      LOV.value.accountSubtypes = await get({ url: 'data/account-subtype'})
    }

    // Handling Normal Balance by subtype
    // const handleSubtype = ({normal_balance}) => {
    //   form.value.normalBalance = normal_balance
    // }

    // Get Account Parent
    const getAccountParent = async () => {
      LOV.value.accountParents = await get({
        // url: `master/accounts/parent?account-subtype-code=${form.value.accountSubtypeCode}`,
        url: 'master/accounts/parent'
      })
    }

    const handleSubtype = () => {
      getAccountParent()
    }

    // watch(isParent, newVal => {
    //   // form.value.isAccountParent = (newVal === 'header')
    //   if (newVal === false) {
    //     getAccountParent()
    //   }
    // })
    // watch(details, newVal => {
    //   form.value.isAccountParent = (newVal === 'header')
    //   if (newVal === 'child') {
    //     // getAccountParent()
    //     console.log(newVal)
    //   }

    // })

    onMounted(() => {
      getAccountParent()
      getSubtypes()
      getTypes()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      LOV.value.details = [
        {
          value: 'none',
          name: $t('apps.masterDataCOA.alert.standalone')
        },
        {
          value: 'child',
          name: $t('apps.masterDataCOA.alert.child')
        }
      ]
    })

    return {
      handleSubtype,
      required,
      details,
      LOV,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      tableColumns,
      accountChildren,
      setPadding,
      getDataPreview,
      options: {
        normalBalance: [
          { text: 'Debit', value: 'DB'},
          { text: 'Credit', value: 'CR' }
        ],
        isParent : [{ item: true, name: 'Is Parent'}]
      }
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.form.accountSubtypeCode = this.form.isParent ? 'PARENT' : this.form.accountSubtypeCode
      this.form.isAccountParent = this.form.isParent ? true: false
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    },
    changeName(value){
      this.form.accountNameDefault = value
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
